import React from "react";
import {
  TeamCard,
  TeamCardContainer,
  TeamContainer,
  TeamInfo,
  TeamName,
  TeamPic,
  TeamTitle,
} from "./styles";

const TeamCards = ({ widthContainer, team }) => {
  return (
    <TeamContainer widthContainer={widthContainer}>
      {team.map((member, i) => (
        <TeamCardContainer>
          <TeamCard index={i}>
            <TeamPic id={`team-pic-${i}`}>
              <img src={member.img} alt="" />
            </TeamPic>
            <TeamInfo id={`team-info-${i}`}>
              <TeamName>{member.name}</TeamName>
              <TeamTitle>{member.position}</TeamTitle>
            </TeamInfo>
          </TeamCard>
        </TeamCardContainer>
      ))}
    </TeamContainer>
  );
};

export default TeamCards;
