import styled from "styled-components";
import { MOBILE_460, TABLET_768, TABLET_992 } from "../../styles/globals/sizes";
import { COLORS } from "../../styles/globals/colors";

export const TeamContainer = styled.div`
  width: ${(props) => props.widthContainer && props.widthContainer};
  grid-template-columns: repeat(4, 265px);
  display: grid;
  margin: 15px auto;
  gap: 30px;
  @media (max-width: 1550px) {
    grid-template-columns: repeat(4, 265px);
  }
  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, 265px);
  }
  @media (max-width: ${TABLET_992}px) {
    grid-template-columns: repeat(2, 265px);
  }
  @media (max-width: ${TABLET_768}px) {
    grid-template-columns: repeat(1, 265px);
  }
  @media (max-width: ${MOBILE_460}px) {
    gap: 0;
  }
`;

export const TeamSectionTitle = styled.h2`
  font-size: 36px;
  color: ${COLORS.redTitle};
  margin: auto;
  padding-top: 90px;
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
  @media (max-width: ${TABLET_992}px) {
    font-size: 30px;
  }
`;

export const TeamSectionSubtitle = styled.h2`
  font-size: 26px;
  color: ${COLORS.grayText};
  margin: auto;
  padding-top: 12px;
  padding-bottom: 15px;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  @media (max-width: ${TABLET_992}px) {
    font-size: 20px;
  }
`;

export const TeamCardContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const TeamCard = styled.div`
  width: 261px;
  height: 397px;
  margin: 20px 0.3%;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  border-radius: 17px;
  overflow: hidden;
  background-color: #eeeeee;
`;

export const TeamPic = styled.picture`
  & > img,
  & > source {
    width: 265px;
    height: 307px;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    object-fit: cover;
    object-position: top;
    transition: height 0.15s ease-out;
  }
`;

export const TeamInfo = styled.div`
  height: -webkit-fill-available;
  text-align: center;
  background: transparent linear-gradient(270deg, #e01a73 0%, #e0281a 100%) 0%
    0% no-repeat padding-box;
  padding-top: 6px;
  padding-bottom: 6px;
  height: 94px;
  width: 288px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  bottom: 0;
  transition: height 0.25s ease-out;
  @media (max-width: ${MOBILE_460}px) {
    min-height: 70px;
  }
`;
export const TeamName = styled.h4`
  text-align: center;
  width: 100%;
  color: white;
  font-size: 17px;
  line-height: 1.1rem;
  font-weight: bold;
  margin-bottom: 3px;
  @media (max-width: ${TABLET_768}px) {
    font-size: 0.9rem;
    line-height: 0.9rem;
  }
`;

export const TeamTitle = styled.h4`
  text-align: center;
  width: 99%;
  color: white;
  font-size: 16px;
  line-height: 1.2rem;
  font-weight: normal;
  margin-top: 10px;
  @media (max-width: ${TABLET_768}px) {
    font-size: 0.7rem;
    line-height: 0.8rem;
    padding-inline: 45px;
  }
`;
